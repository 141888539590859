export const ACheckbox = () => import('../../components/Base/ACheckbox.vue' /* webpackChunkName: "components/a-checkbox" */).then(c => wrapFunctional(c.default || c))
export const ACheckboxInput = () => import('../../components/Base/ACheckboxInput.vue' /* webpackChunkName: "components/a-checkbox-input" */).then(c => wrapFunctional(c.default || c))
export const ADatePicker = () => import('../../components/Base/ADatePicker.vue' /* webpackChunkName: "components/a-date-picker" */).then(c => wrapFunctional(c.default || c))
export const AInput = () => import('../../components/Base/AInput.vue' /* webpackChunkName: "components/a-input" */).then(c => wrapFunctional(c.default || c))
export const APagination = () => import('../../components/Base/APagination.vue' /* webpackChunkName: "components/a-pagination" */).then(c => wrapFunctional(c.default || c))
export const APanel = () => import('../../components/Base/APanel.vue' /* webpackChunkName: "components/a-panel" */).then(c => wrapFunctional(c.default || c))
export const AProgressBar = () => import('../../components/Base/AProgressBar.vue' /* webpackChunkName: "components/a-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const ARadioButton = () => import('../../components/Base/ARadioButton.vue' /* webpackChunkName: "components/a-radio-button" */).then(c => wrapFunctional(c.default || c))
export const ARadioGroup = () => import('../../components/Base/ARadioGroup.vue' /* webpackChunkName: "components/a-radio-group" */).then(c => wrapFunctional(c.default || c))
export const ASelect = () => import('../../components/Base/ASelect.vue' /* webpackChunkName: "components/a-select" */).then(c => wrapFunctional(c.default || c))
export const ASlider = () => import('../../components/Base/ASlider.vue' /* webpackChunkName: "components/a-slider" */).then(c => wrapFunctional(c.default || c))
export const ATextArea = () => import('../../components/Base/ATextArea.vue' /* webpackChunkName: "components/a-text-area" */).then(c => wrapFunctional(c.default || c))
export const AppBreadcrumbs = () => import('../../components/Base/AppBreadcrumbs.vue' /* webpackChunkName: "components/app-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const ArrowCollapse = () => import('../../components/Base/ArrowCollapse.vue' /* webpackChunkName: "components/arrow-collapse" */).then(c => wrapFunctional(c.default || c))
export const ArrowMore = () => import('../../components/Base/ArrowMore.vue' /* webpackChunkName: "components/arrow-more" */).then(c => wrapFunctional(c.default || c))
export const CategoryCard = () => import('../../components/Base/CategoryCard.vue' /* webpackChunkName: "components/category-card" */).then(c => wrapFunctional(c.default || c))
export const CategoryImage = () => import('../../components/Base/CategoryImage.vue' /* webpackChunkName: "components/category-image" */).then(c => wrapFunctional(c.default || c))
export const CustomDatePicker = () => import('../../components/Base/CustomDatePicker.vue' /* webpackChunkName: "components/custom-date-picker" */).then(c => wrapFunctional(c.default || c))
export const CustomSelect = () => import('../../components/Base/CustomSelect.vue' /* webpackChunkName: "components/custom-select" */).then(c => wrapFunctional(c.default || c))
export const IconHandler = () => import('../../components/Base/IconHandler.vue' /* webpackChunkName: "components/icon-handler" */).then(c => wrapFunctional(c.default || c))
export const LinkGoBack = () => import('../../components/Base/LinkGoBack.vue' /* webpackChunkName: "components/link-go-back" */).then(c => wrapFunctional(c.default || c))
export const LoadMoreText = () => import('../../components/Base/LoadMoreText.vue' /* webpackChunkName: "components/load-more-text" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Base/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const MobileSelect = () => import('../../components/Base/MobileSelect.vue' /* webpackChunkName: "components/mobile-select" */).then(c => wrapFunctional(c.default || c))
export const OffersSlider = () => import('../../components/Base/OffersSlider.vue' /* webpackChunkName: "components/offers-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductsCollapse = () => import('../../components/Base/ProductsCollapse.vue' /* webpackChunkName: "components/products-collapse" */).then(c => wrapFunctional(c.default || c))
export const ProductsGrid = () => import('../../components/Base/ProductsGrid.vue' /* webpackChunkName: "components/products-grid" */).then(c => wrapFunctional(c.default || c))
export const ProductsSlider = () => import('../../components/Base/ProductsSlider.vue' /* webpackChunkName: "components/products-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductsSliderCollapse = () => import('../../components/Base/ProductsSliderCollapse.vue' /* webpackChunkName: "components/products-slider-collapse" */).then(c => wrapFunctional(c.default || c))
export const SearchInput = () => import('../../components/Base/SearchInput.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c))
export const SeoText = () => import('../../components/Base/SeoText.vue' /* webpackChunkName: "components/seo-text" */).then(c => wrapFunctional(c.default || c))
export const SmallProductsSlider = () => import('../../components/Base/SmallProductsSlider.vue' /* webpackChunkName: "components/small-products-slider" */).then(c => wrapFunctional(c.default || c))
export const StarHandler = () => import('../../components/Base/StarHandler.vue' /* webpackChunkName: "components/star-handler" */).then(c => wrapFunctional(c.default || c))
export const StarsPanel = () => import('../../components/Base/StarsPanel.vue' /* webpackChunkName: "components/stars-panel" */).then(c => wrapFunctional(c.default || c))
export const TextContent = () => import('../../components/Base/TextContent.vue' /* webpackChunkName: "components/text-content" */).then(c => wrapFunctional(c.default || c))
export const ThumbDownHandler = () => import('../../components/Base/ThumbDownHandler.vue' /* webpackChunkName: "components/thumb-down-handler" */).then(c => wrapFunctional(c.default || c))
export const ThumbUpHandler = () => import('../../components/Base/ThumbUpHandler.vue' /* webpackChunkName: "components/thumb-up-handler" */).then(c => wrapFunctional(c.default || c))
export const TitleWithAll = () => import('../../components/Base/TitleWithAll.vue' /* webpackChunkName: "components/title-with-all" */).then(c => wrapFunctional(c.default || c))
export const VideoIcon = () => import('../../components/Base/VideoIcon.vue' /* webpackChunkName: "components/video-icon" */).then(c => wrapFunctional(c.default || c))
export const InputComment = () => import('../../components/Base/Input/InputComment.vue' /* webpackChunkName: "components/input-comment" */).then(c => wrapFunctional(c.default || c))
export const InputEmail = () => import('../../components/Base/Input/InputEmail.vue' /* webpackChunkName: "components/input-email" */).then(c => wrapFunctional(c.default || c))
export const InputName = () => import('../../components/Base/Input/InputName.vue' /* webpackChunkName: "components/input-name" */).then(c => wrapFunctional(c.default || c))
export const InputPassword = () => import('../../components/Base/Input/InputPassword.vue' /* webpackChunkName: "components/input-password" */).then(c => wrapFunctional(c.default || c))
export const InputPhone = () => import('../../components/Base/Input/InputPhone.vue' /* webpackChunkName: "components/input-phone" */).then(c => wrapFunctional(c.default || c))
export const InputSurname = () => import('../../components/Base/Input/InputSurname.vue' /* webpackChunkName: "components/input-surname" */).then(c => wrapFunctional(c.default || c))
export const BlogCard = () => import('../../components/Blog/BlogCard.vue' /* webpackChunkName: "components/blog-card" */).then(c => wrapFunctional(c.default || c))
export const BlogCategories = () => import('../../components/Blog/BlogCategories.vue' /* webpackChunkName: "components/blog-categories" */).then(c => wrapFunctional(c.default || c))
export const BlogCategoriesMob = () => import('../../components/Blog/BlogCategoriesMob.vue' /* webpackChunkName: "components/blog-categories-mob" */).then(c => wrapFunctional(c.default || c))
export const BlogItems = () => import('../../components/Blog/BlogItems.vue' /* webpackChunkName: "components/blog-items" */).then(c => wrapFunctional(c.default || c))
export const BlogSidebar = () => import('../../components/Blog/BlogSidebar.vue' /* webpackChunkName: "components/blog-sidebar" */).then(c => wrapFunctional(c.default || c))
export const ASwitcher = () => import('../../components/ASwitcher/ASwitcher.vue' /* webpackChunkName: "components/a-switcher" */).then(c => wrapFunctional(c.default || c))
export const BlogOtherArticles = () => import('../../components/BlogPost/BlogOtherArticles.vue' /* webpackChunkName: "components/blog-other-articles" */).then(c => wrapFunctional(c.default || c))
export const BlogPostAnotation = () => import('../../components/BlogPost/BlogPostAnotation.vue' /* webpackChunkName: "components/blog-post-anotation" */).then(c => wrapFunctional(c.default || c))
export const BlogPostComment = () => import('../../components/BlogPost/BlogPostComment.vue' /* webpackChunkName: "components/blog-post-comment" */).then(c => wrapFunctional(c.default || c))
export const BlogPostComments = () => import('../../components/BlogPost/BlogPostComments.vue' /* webpackChunkName: "components/blog-post-comments" */).then(c => wrapFunctional(c.default || c))
export const BlogPostContent = () => import('../../components/BlogPost/BlogPostContent.vue' /* webpackChunkName: "components/blog-post-content" */).then(c => wrapFunctional(c.default || c))
export const BlogPostHero = () => import('../../components/BlogPost/BlogPostHero.vue' /* webpackChunkName: "components/blog-post-hero" */).then(c => wrapFunctional(c.default || c))
export const BlogPostSidebar = () => import('../../components/BlogPost/BlogPostSidebar.vue' /* webpackChunkName: "components/blog-post-sidebar" */).then(c => wrapFunctional(c.default || c))
export const BlogPostSidebarRelated = () => import('../../components/BlogPost/BlogPostSidebarRelated.vue' /* webpackChunkName: "components/blog-post-sidebar-related" */).then(c => wrapFunctional(c.default || c))
export const BrandBlocks = () => import('../../components/Brand/BrandBlocks.vue' /* webpackChunkName: "components/brand-blocks" */).then(c => wrapFunctional(c.default || c))
export const BrandCategoriesSection = () => import('../../components/Brand/BrandCategoriesSection.vue' /* webpackChunkName: "components/brand-categories-section" */).then(c => wrapFunctional(c.default || c))
export const BrandFullBannerSection = () => import('../../components/Brand/BrandFullBannerSection.vue' /* webpackChunkName: "components/brand-full-banner-section" */).then(c => wrapFunctional(c.default || c))
export const BrandInfoBlock = () => import('../../components/Brand/BrandInfoBlock.vue' /* webpackChunkName: "components/brand-info-block" */).then(c => wrapFunctional(c.default || c))
export const BrandInfoBlockCategorySection = () => import('../../components/Brand/BrandInfoBlockCategorySection.vue' /* webpackChunkName: "components/brand-info-block-category-section" */).then(c => wrapFunctional(c.default || c))
export const BrandInfoBlockSaleSection = () => import('../../components/Brand/BrandInfoBlockSaleSection.vue' /* webpackChunkName: "components/brand-info-block-sale-section" */).then(c => wrapFunctional(c.default || c))
export const BrandOffersSection = () => import('../../components/Brand/BrandOffersSection.vue' /* webpackChunkName: "components/brand-offers-section" */).then(c => wrapFunctional(c.default || c))
export const BrandProducts = () => import('../../components/Brand/BrandProducts.vue' /* webpackChunkName: "components/brand-products" */).then(c => wrapFunctional(c.default || c))
export const BrandsCard = () => import('../../components/Brands/BrandsCard.vue' /* webpackChunkName: "components/brands-card" */).then(c => wrapFunctional(c.default || c))
export const BrandsItemsCards = () => import('../../components/Brands/BrandsItemsCards.vue' /* webpackChunkName: "components/brands-items-cards" */).then(c => wrapFunctional(c.default || c))
export const BrandsItemsText = () => import('../../components/Brands/BrandsItemsText.vue' /* webpackChunkName: "components/brands-items-text" */).then(c => wrapFunctional(c.default || c))
export const BrandsItemsTextCards = () => import('../../components/Brands/BrandsItemsTextCards.vue' /* webpackChunkName: "components/brands-items-text-cards" */).then(c => wrapFunctional(c.default || c))
export const BrandsTextItem = () => import('../../components/Brands/BrandsTextItem.vue' /* webpackChunkName: "components/brands-text-item" */).then(c => wrapFunctional(c.default || c))
export const BrandsBlock = () => import('../../components/BrandsBlock/BrandsBlock.vue' /* webpackChunkName: "components/brands-block" */).then(c => wrapFunctional(c.default || c))
export const CabinetBonusCard = () => import('../../components/Cabinet/CabinetBonusCard.vue' /* webpackChunkName: "components/cabinet-bonus-card" */).then(c => wrapFunctional(c.default || c))
export const CatalogCategoryController = () => import('../../components/Catalog/CatalogCategoryController.vue' /* webpackChunkName: "components/catalog-category-controller" */).then(c => wrapFunctional(c.default || c))
export const CatalogController = () => import('../../components/Catalog/CatalogController.vue' /* webpackChunkName: "components/catalog-controller" */).then(c => wrapFunctional(c.default || c))
export const CatalogHeader = () => import('../../components/Catalog/CatalogHeader.vue' /* webpackChunkName: "components/catalog-header" */).then(c => wrapFunctional(c.default || c))
export const CatalogMobFilterBtns = () => import('../../components/Catalog/CatalogMobFilterBtns.vue' /* webpackChunkName: "components/catalog-mob-filter-btns" */).then(c => wrapFunctional(c.default || c))
export const CatalogMobFilters = () => import('../../components/Catalog/CatalogMobFilters.vue' /* webpackChunkName: "components/catalog-mob-filters" */).then(c => wrapFunctional(c.default || c))
export const CatalogProducts = () => import('../../components/Catalog/CatalogProducts.vue' /* webpackChunkName: "components/catalog-products" */).then(c => wrapFunctional(c.default || c))
export const CatalogProductsStocks = () => import('../../components/Catalog/CatalogProductsStocks.vue' /* webpackChunkName: "components/catalog-products-stocks" */).then(c => wrapFunctional(c.default || c))
export const CatalogSubCategories = () => import('../../components/Catalog/CatalogSubCategories.vue' /* webpackChunkName: "components/catalog-sub-categories" */).then(c => wrapFunctional(c.default || c))
export const CatalogTags = () => import('../../components/Catalog/CatalogTags.vue' /* webpackChunkName: "components/catalog-tags" */).then(c => wrapFunctional(c.default || c))
export const CatalogLazyLoadBar = () => import('../../components/Catalog/LazyLoadBar.vue' /* webpackChunkName: "components/catalog-lazy-load-bar" */).then(c => wrapFunctional(c.default || c))
export const CatalogModal = () => import('../../components/CatalogModal/CatalogModal.vue' /* webpackChunkName: "components/catalog-modal" */).then(c => wrapFunctional(c.default || c))
export const CatalogModalCategories = () => import('../../components/CatalogModal/CatalogModalCategories.vue' /* webpackChunkName: "components/catalog-modal-categories" */).then(c => wrapFunctional(c.default || c))
export const CatalogModalCategoriesItem = () => import('../../components/CatalogModal/CatalogModalCategoriesItem.vue' /* webpackChunkName: "components/catalog-modal-categories-item" */).then(c => wrapFunctional(c.default || c))
export const CatalogModalController = () => import('../../components/CatalogModal/CatalogModalController.vue' /* webpackChunkName: "components/catalog-modal-controller" */).then(c => wrapFunctional(c.default || c))
export const CatalogModalFooter = () => import('../../components/CatalogModal/CatalogModalFooter.vue' /* webpackChunkName: "components/catalog-modal-footer" */).then(c => wrapFunctional(c.default || c))
export const CatalogModalItems = () => import('../../components/CatalogModal/CatalogModalItems.vue' /* webpackChunkName: "components/catalog-modal-items" */).then(c => wrapFunctional(c.default || c))
export const CatalogModalMenu = () => import('../../components/CatalogModal/CatalogModalMenu.vue' /* webpackChunkName: "components/catalog-modal-menu" */).then(c => wrapFunctional(c.default || c))
export const CatalogModalPopular = () => import('../../components/CatalogModal/CatalogModalPopular.vue' /* webpackChunkName: "components/catalog-modal-popular" */).then(c => wrapFunctional(c.default || c))
export const CategoryTitle = () => import('../../components/Category/CategoryTitle.vue' /* webpackChunkName: "components/category-title" */).then(c => wrapFunctional(c.default || c))
export const CheckoutBonusProgress = () => import('../../components/Checkout/CheckoutBonusProgress.vue' /* webpackChunkName: "components/checkout-bonus-progress" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDelivery = () => import('../../components/Checkout/CheckoutDelivery.vue' /* webpackChunkName: "components/checkout-delivery" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryData = () => import('../../components/Checkout/CheckoutDeliveryData.vue' /* webpackChunkName: "components/checkout-delivery-data" */).then(c => wrapFunctional(c.default || c))
export const CheckoutEmpty = () => import('../../components/Checkout/CheckoutEmpty.vue' /* webpackChunkName: "components/checkout-empty" */).then(c => wrapFunctional(c.default || c))
export const CheckoutForm = () => import('../../components/Checkout/CheckoutForm.vue' /* webpackChunkName: "components/checkout-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPayment = () => import('../../components/Checkout/CheckoutPayment.vue' /* webpackChunkName: "components/checkout-payment" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPersonalData = () => import('../../components/Checkout/CheckoutPersonalData.vue' /* webpackChunkName: "components/checkout-personal-data" */).then(c => wrapFunctional(c.default || c))
export const CheckoutRecall = () => import('../../components/Checkout/CheckoutRecall.vue' /* webpackChunkName: "components/checkout-recall" */).then(c => wrapFunctional(c.default || c))
export const CheckoutTotalInfo = () => import('../../components/Checkout/CheckoutTotalInfo.vue' /* webpackChunkName: "components/checkout-total-info" */).then(c => wrapFunctional(c.default || c))
export const CompareCharacteristics = () => import('../../components/Compare/CompareCharacteristics.vue' /* webpackChunkName: "components/compare-characteristics" */).then(c => wrapFunctional(c.default || c))
export const CompareItem = () => import('../../components/Compare/CompareItem.vue' /* webpackChunkName: "components/compare-item" */).then(c => wrapFunctional(c.default || c))
export const CompareItems = () => import('../../components/Compare/CompareItems.vue' /* webpackChunkName: "components/compare-items" */).then(c => wrapFunctional(c.default || c))
export const CompareProduct = () => import('../../components/Compare/CompareProduct.vue' /* webpackChunkName: "components/compare-product" */).then(c => wrapFunctional(c.default || c))
export const CompareSidebar = () => import('../../components/Compare/CompareSidebar.vue' /* webpackChunkName: "components/compare-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CompareSidebarFilter = () => import('../../components/Compare/CompareSidebarFilter.vue' /* webpackChunkName: "components/compare-sidebar-filter" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FooterInfoCol = () => import('../../components/Footer/FooterInfoCol.vue' /* webpackChunkName: "components/footer-info-col" */).then(c => wrapFunctional(c.default || c))
export const FooterMenu = () => import('../../components/Footer/FooterMenu.vue' /* webpackChunkName: "components/footer-menu" */).then(c => wrapFunctional(c.default || c))
export const FooterSocIcons = () => import('../../components/Footer/FooterSocIcons.vue' /* webpackChunkName: "components/footer-soc-icons" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeaderAccount = () => import('../../components/Header/HeaderAccount.vue' /* webpackChunkName: "components/header-account" */).then(c => wrapFunctional(c.default || c))
export const HeaderActionsBar = () => import('../../components/Header/HeaderActionsBar.vue' /* webpackChunkName: "components/header-actions-bar" */).then(c => wrapFunctional(c.default || c))
export const HeaderBurger = () => import('../../components/Header/HeaderBurger.vue' /* webpackChunkName: "components/header-burger" */).then(c => wrapFunctional(c.default || c))
export const HeaderCart = () => import('../../components/Header/HeaderCart.vue' /* webpackChunkName: "components/header-cart" */).then(c => wrapFunctional(c.default || c))
export const HeaderCatalogBtn = () => import('../../components/Header/HeaderCatalogBtn.vue' /* webpackChunkName: "components/header-catalog-btn" */).then(c => wrapFunctional(c.default || c))
export const HeaderCity = () => import('../../components/Header/HeaderCity.vue' /* webpackChunkName: "components/header-city" */).then(c => wrapFunctional(c.default || c))
export const HeaderCitySelect = () => import('../../components/Header/HeaderCitySelect.vue' /* webpackChunkName: "components/header-city-select" */).then(c => wrapFunctional(c.default || c))
export const HeaderDelivery = () => import('../../components/Header/HeaderDelivery.vue' /* webpackChunkName: "components/header-delivery" */).then(c => wrapFunctional(c.default || c))
export const HeaderFeatureBtns = () => import('../../components/Header/HeaderFeatureBtns.vue' /* webpackChunkName: "components/header-feature-btns" */).then(c => wrapFunctional(c.default || c))
export const HeaderLocaleSwitcher = () => import('../../components/Header/HeaderLocaleSwitcher.vue' /* webpackChunkName: "components/header-locale-switcher" */).then(c => wrapFunctional(c.default || c))
export const HeaderMainBar = () => import('../../components/Header/HeaderMainBar.vue' /* webpackChunkName: "components/header-main-bar" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenu = () => import('../../components/Header/HeaderMenu.vue' /* webpackChunkName: "components/header-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderMob = () => import('../../components/Header/HeaderMob.vue' /* webpackChunkName: "components/header-mob" */).then(c => wrapFunctional(c.default || c))
export const HeaderPhones = () => import('../../components/Header/HeaderPhones.vue' /* webpackChunkName: "components/header-phones" */).then(c => wrapFunctional(c.default || c))
export const HeaderSearch = () => import('../../components/Header/HeaderSearch.vue' /* webpackChunkName: "components/header-search" */).then(c => wrapFunctional(c.default || c))
export const HeaderTopBar = () => import('../../components/Header/HeaderTopBar.vue' /* webpackChunkName: "components/header-top-bar" */).then(c => wrapFunctional(c.default || c))
export const HomeBrandsBlockSection = () => import('../../components/Home/HomeBrandsBlockSection.vue' /* webpackChunkName: "components/home-brands-block-section" */).then(c => wrapFunctional(c.default || c))
export const HomeCategories = () => import('../../components/Home/HomeCategories.vue' /* webpackChunkName: "components/home-categories" */).then(c => wrapFunctional(c.default || c))
export const HomeCategoriesList = () => import('../../components/Home/HomeCategoriesList.vue' /* webpackChunkName: "components/home-categories-list" */).then(c => wrapFunctional(c.default || c))
export const HomeCategorySliderSection = () => import('../../components/Home/HomeCategorySliderSection.vue' /* webpackChunkName: "components/home-category-slider-section" */).then(c => wrapFunctional(c.default || c))
export const HomeFirstBlock = () => import('../../components/Home/HomeFirstBlock.vue' /* webpackChunkName: "components/home-first-block" */).then(c => wrapFunctional(c.default || c))
export const HomeFirstBlockSection = () => import('../../components/Home/HomeFirstBlockSection.vue' /* webpackChunkName: "components/home-first-block-section" */).then(c => wrapFunctional(c.default || c))
export const HomeStaticBlocksSection = () => import('../../components/Home/HomeStaticBlocksSection.vue' /* webpackChunkName: "components/home-static-blocks-section" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/Loader/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const LoginFooter = () => import('../../components/Login/LoginFooter.vue' /* webpackChunkName: "components/login-footer" */).then(c => wrapFunctional(c.default || c))
export const LoginForm = () => import('../../components/Login/LoginForm.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c))
export const LoginSoc = () => import('../../components/Login/LoginSoc.vue' /* webpackChunkName: "components/login-soc" */).then(c => wrapFunctional(c.default || c))
export const MenuMob = () => import('../../components/MenuMob/MenuMob.vue' /* webpackChunkName: "components/menu-mob" */).then(c => wrapFunctional(c.default || c))
export const MenuMobCategories = () => import('../../components/MenuMob/MenuMobCategories.vue' /* webpackChunkName: "components/menu-mob-categories" */).then(c => wrapFunctional(c.default || c))
export const MenuMobCategoriesImages = () => import('../../components/MenuMob/MenuMobCategoriesImages.vue' /* webpackChunkName: "components/menu-mob-categories-images" */).then(c => wrapFunctional(c.default || c))
export const MenuMobCategoriesList = () => import('../../components/MenuMob/MenuMobCategoriesList.vue' /* webpackChunkName: "components/menu-mob-categories-list" */).then(c => wrapFunctional(c.default || c))
export const MenuMobHeader = () => import('../../components/MenuMob/MenuMobHeader.vue' /* webpackChunkName: "components/menu-mob-header" */).then(c => wrapFunctional(c.default || c))
export const MenuMobInfoList = () => import('../../components/MenuMob/MenuMobInfoList.vue' /* webpackChunkName: "components/menu-mob-info-list" */).then(c => wrapFunctional(c.default || c))
export const MenuMobItem = () => import('../../components/MenuMob/MenuMobItem.vue' /* webpackChunkName: "components/menu-mob-item" */).then(c => wrapFunctional(c.default || c))
export const MenuMobMain = () => import('../../components/MenuMob/MenuMobMain.vue' /* webpackChunkName: "components/menu-mob-main" */).then(c => wrapFunctional(c.default || c))
export const MenuMobSearch = () => import('../../components/MenuMob/MenuMobSearch.vue' /* webpackChunkName: "components/menu-mob-search" */).then(c => wrapFunctional(c.default || c))
export const MenuMobSubHeader = () => import('../../components/MenuMob/MenuMobSubHeader.vue' /* webpackChunkName: "components/menu-mob-sub-header" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const ModalContent = () => import('../../components/Modal/ModalContent.vue' /* webpackChunkName: "components/modal-content" */).then(c => wrapFunctional(c.default || c))
export const ModalsAddedToCartModal = () => import('../../components/Modals/AddedToCartModal.vue' /* webpackChunkName: "components/modals-added-to-cart-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsAvailableModal = () => import('../../components/Modals/AvailableModal.vue' /* webpackChunkName: "components/modals-available-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsCheaperModal = () => import('../../components/Modals/CheaperModal.vue' /* webpackChunkName: "components/modals-cheaper-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsCityModal = () => import('../../components/Modals/CityModal.vue' /* webpackChunkName: "components/modals-city-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsCompareListModal = () => import('../../components/Modals/CompareListModal.vue' /* webpackChunkName: "components/modals-compare-list-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsConsultModal = () => import('../../components/Modals/ConsultModal.vue' /* webpackChunkName: "components/modals-consult-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsInfoModal = () => import('../../components/Modals/InfoModal.vue' /* webpackChunkName: "components/modals-info-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsLoginModal = () => import('../../components/Modals/LoginModal.vue' /* webpackChunkName: "components/modals-login-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsOfertModal = () => import('../../components/Modals/OfertModal.vue' /* webpackChunkName: "components/modals-ofert-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsProductImagesModal = () => import('../../components/Modals/ProductImagesModal.vue' /* webpackChunkName: "components/modals-product-images-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsQuickViewModal = () => import('../../components/Modals/QuickViewModal.vue' /* webpackChunkName: "components/modals-quick-view-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsRestorePasswordModal = () => import('../../components/Modals/RestorePasswordModal.vue' /* webpackChunkName: "components/modals-restore-password-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsReviewPlaceholderModal = () => import('../../components/Modals/ReviewPlaceholderModal.vue' /* webpackChunkName: "components/modals-review-placeholder-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsSendReviewModal = () => import('../../components/Modals/SendReviewModal.vue' /* webpackChunkName: "components/modals-send-review-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsSignupModal = () => import('../../components/Modals/SignupModal.vue' /* webpackChunkName: "components/modals-signup-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsSortModal = () => import('../../components/Modals/SortModal.vue' /* webpackChunkName: "components/modals-sort-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsThanksModal = () => import('../../components/Modals/ThanksModal.vue' /* webpackChunkName: "components/modals-thanks-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsLoginBeforeSendReview = () => import('../../components/Modals/loginBeforeSendReview.vue' /* webpackChunkName: "components/modals-login-before-send-review" */).then(c => wrapFunctional(c.default || c))
export const OfferCard = () => import('../../components/OfferCard/OfferCard.vue' /* webpackChunkName: "components/offer-card" */).then(c => wrapFunctional(c.default || c))
export const OfferCardBg = () => import('../../components/OfferCard/OfferCardBg.vue' /* webpackChunkName: "components/offer-card-bg" */).then(c => wrapFunctional(c.default || c))
export const OfferImageCard = () => import('../../components/OfferCard/OfferImageCard.vue' /* webpackChunkName: "components/offer-image-card" */).then(c => wrapFunctional(c.default || c))
export const OfferCardImage = () => import('../../components/OfferCardImage/OfferCardImage.vue' /* webpackChunkName: "components/offer-card-image" */).then(c => wrapFunctional(c.default || c))
export const OfferCardImageBg = () => import('../../components/OfferCardImage/OfferCardImageBg.vue' /* webpackChunkName: "components/offer-card-image-bg" */).then(c => wrapFunctional(c.default || c))
export const PageContent = () => import('../../components/Page/PageContent.vue' /* webpackChunkName: "components/page-content" */).then(c => wrapFunctional(c.default || c))
export const Page404 = () => import('../../components/Page404/Page404.vue' /* webpackChunkName: "components/page404" */).then(c => wrapFunctional(c.default || c))
export const PageSections = () => import('../../components/PageSections/PageSections.vue' /* webpackChunkName: "components/page-sections" */).then(c => wrapFunctional(c.default || c))
export const PageSectionsSeo = () => import('../../components/PageSections/PageSectionsSeo.vue' /* webpackChunkName: "components/page-sections-seo" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersBrandPlaceholders = () => import('../../components/Placeholders/BrandPlaceholders.vue' /* webpackChunkName: "components/placeholders-brand-placeholders" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersBrandsPlaceholders = () => import('../../components/Placeholders/BrandsPlaceholders.vue' /* webpackChunkName: "components/placeholders-brands-placeholders" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersCarouselProductsPlaceholder = () => import('../../components/Placeholders/CarouselProductsPlaceholder.vue' /* webpackChunkName: "components/placeholders-carousel-products-placeholder" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersCatalogPlaceholders = () => import('../../components/Placeholders/CatalogPlaceholders.vue' /* webpackChunkName: "components/placeholders-catalog-placeholders" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersCatalogProductsPlaceholders = () => import('../../components/Placeholders/CatalogProductsPlaceholders.vue' /* webpackChunkName: "components/placeholders-catalog-products-placeholders" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersCheckoutPlaceholders = () => import('../../components/Placeholders/CheckoutPlaceholders.vue' /* webpackChunkName: "components/placeholders-checkout-placeholders" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersDefaultPlaceholders = () => import('../../components/Placeholders/DefaultPlaceholders.vue' /* webpackChunkName: "components/placeholders-default-placeholders" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersHomePlaceholders = () => import('../../components/Placeholders/HomePlaceholders.vue' /* webpackChunkName: "components/placeholders-home-placeholders" */).then(c => wrapFunctional(c.default || c))
export const PlaceholdersProductInfoPlaceholders = () => import('../../components/Placeholders/ProductInfoPlaceholders.vue' /* webpackChunkName: "components/placeholders-product-info-placeholders" */).then(c => wrapFunctional(c.default || c))
export const Preloader = () => import('../../components/Preloader/Preloader.vue' /* webpackChunkName: "components/preloader" */).then(c => wrapFunctional(c.default || c))
export const ProductAvailable = () => import('../../components/Product/ProductAvailable.vue' /* webpackChunkName: "components/product-available" */).then(c => wrapFunctional(c.default || c))
export const ProductBarMob = () => import('../../components/Product/ProductBarMob.vue' /* webpackChunkName: "components/product-bar-mob" */).then(c => wrapFunctional(c.default || c))
export const ProductBrandItems = () => import('../../components/Product/ProductBrandItems.vue' /* webpackChunkName: "components/product-brand-items" */).then(c => wrapFunctional(c.default || c))
export const ProductCntChooser = () => import('../../components/Product/ProductCntChooser.vue' /* webpackChunkName: "components/product-cnt-chooser" */).then(c => wrapFunctional(c.default || c))
export const ProductCntCounter = () => import('../../components/Product/ProductCntCounter.vue' /* webpackChunkName: "components/product-cnt-counter" */).then(c => wrapFunctional(c.default || c))
export const ProductDelivery = () => import('../../components/Product/ProductDelivery.vue' /* webpackChunkName: "components/product-delivery" */).then(c => wrapFunctional(c.default || c))
export const ProductFeaturesBtn = () => import('../../components/Product/ProductFeaturesBtn.vue' /* webpackChunkName: "components/product-features-btn" */).then(c => wrapFunctional(c.default || c))
export const ProductImage = () => import('../../components/Product/ProductImage.vue' /* webpackChunkName: "components/product-image" */).then(c => wrapFunctional(c.default || c))
export const ProductImagesSlider = () => import('../../components/Product/ProductImagesSlider.vue' /* webpackChunkName: "components/product-images-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductLabel = () => import('../../components/Product/ProductLabel.vue' /* webpackChunkName: "components/product-label" */).then(c => wrapFunctional(c.default || c))
export const ProductLabels = () => import('../../components/Product/ProductLabels.vue' /* webpackChunkName: "components/product-labels" */).then(c => wrapFunctional(c.default || c))
export const ProductMicrodata = () => import('../../components/Product/ProductMicrodata.vue' /* webpackChunkName: "components/product-microdata" */).then(c => wrapFunctional(c.default || c))
export const ProductOneClickPopup = () => import('../../components/Product/ProductOneClickPopup.vue' /* webpackChunkName: "components/product-one-click-popup" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../components/Product/ProductPrice.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const ProductPriceBlock = () => import('../../components/Product/ProductPriceBlock.vue' /* webpackChunkName: "components/product-price-block" */).then(c => wrapFunctional(c.default || c))
export const ProductReview = () => import('../../components/Product/ProductReview.vue' /* webpackChunkName: "components/product-review" */).then(c => wrapFunctional(c.default || c))
export const ProductReviewBlock = () => import('../../components/Product/ProductReviewBlock.vue' /* webpackChunkName: "components/product-review-block" */).then(c => wrapFunctional(c.default || c))
export const ProductSimilarItems = () => import('../../components/Product/ProductSimilarItems.vue' /* webpackChunkName: "components/product-similar-items" */).then(c => wrapFunctional(c.default || c))
export const ProductSku = () => import('../../components/Product/ProductSku.vue' /* webpackChunkName: "components/product-sku" */).then(c => wrapFunctional(c.default || c))
export const ProductTitle = () => import('../../components/Product/ProductTitle.vue' /* webpackChunkName: "components/product-title" */).then(c => wrapFunctional(c.default || c))
export const ProductWatchedItems = () => import('../../components/Product/ProductWatchedItems.vue' /* webpackChunkName: "components/product-watched-items" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/ProductCard/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCardActions = () => import('../../components/ProductCard/ProductCardActions.vue' /* webpackChunkName: "components/product-card-actions" */).then(c => wrapFunctional(c.default || c))
export const ProductCardLabels = () => import('../../components/ProductCard/ProductCardLabels.vue' /* webpackChunkName: "components/product-card-labels" */).then(c => wrapFunctional(c.default || c))
export const ProductCardPrice = () => import('../../components/ProductCard/ProductCardPrice.vue' /* webpackChunkName: "components/product-card-price" */).then(c => wrapFunctional(c.default || c))
export const ProductCardReviews = () => import('../../components/ProductCard/ProductCardReviews.vue' /* webpackChunkName: "components/product-card-reviews" */).then(c => wrapFunctional(c.default || c))
export const ProductCardFields = () => import('../../components/ProductCard/product-card-fields.ts' /* webpackChunkName: "components/product-card-fields" */).then(c => wrapFunctional(c.default || c))
export const ProductCardGtag = () => import('../../components/ProductCard/product-card-gtag.ts' /* webpackChunkName: "components/product-card-gtag" */).then(c => wrapFunctional(c.default || c))
export const RestorePasswordForm = () => import('../../components/RestorePassword/RestorePasswordForm.vue' /* webpackChunkName: "components/restore-password-form" */).then(c => wrapFunctional(c.default || c))
export const SendReviewForm = () => import('../../components/SendReview/SendReviewForm.vue' /* webpackChunkName: "components/send-review-form" */).then(c => wrapFunctional(c.default || c))
export const SendReviewPhoto = () => import('../../components/SendReview/SendReviewPhoto.vue' /* webpackChunkName: "components/send-review-photo" */).then(c => wrapFunctional(c.default || c))
export const SendReviewStars = () => import('../../components/SendReview/SendReviewStars.vue' /* webpackChunkName: "components/send-review-stars" */).then(c => wrapFunctional(c.default || c))
export const SignupForm = () => import('../../components/Signup/SignupForm.vue' /* webpackChunkName: "components/signup-form" */).then(c => wrapFunctional(c.default || c))
export const SliderProductsHits = () => import('../../components/Slider/SliderProductsHits.vue' /* webpackChunkName: "components/slider-products-hits" */).then(c => wrapFunctional(c.default || c))
export const SliderProductsNew = () => import('../../components/Slider/SliderProductsNew.vue' /* webpackChunkName: "components/slider-products-new" */).then(c => wrapFunctional(c.default || c))
export const SliderProductsStock = () => import('../../components/Slider/SliderProductsStock.vue' /* webpackChunkName: "components/slider-products-stock" */).then(c => wrapFunctional(c.default || c))
export const SliderProductsWatched = () => import('../../components/Slider/SliderProductsWatched.vue' /* webpackChunkName: "components/slider-products-watched" */).then(c => wrapFunctional(c.default || c))
export const StockBanner = () => import('../../components/Stock/StockBanner.vue' /* webpackChunkName: "components/stock-banner" */).then(c => wrapFunctional(c.default || c))
export const StockFullOffer = () => import('../../components/Stock/StockFullOffer.vue' /* webpackChunkName: "components/stock-full-offer" */).then(c => wrapFunctional(c.default || c))
export const StockOffersSlider = () => import('../../components/Stock/StockOffersSlider.vue' /* webpackChunkName: "components/stock-offers-slider" */).then(c => wrapFunctional(c.default || c))
export const StockProducts = () => import('../../components/Stock/StockProducts.vue' /* webpackChunkName: "components/stock-products" */).then(c => wrapFunctional(c.default || c))
export const StockTextOffer = () => import('../../components/Stock/StockTextOffer.vue' /* webpackChunkName: "components/stock-text-offer" */).then(c => wrapFunctional(c.default || c))
export const StocksItem = () => import('../../components/Stocks/StocksItem.vue' /* webpackChunkName: "components/stocks-item" */).then(c => wrapFunctional(c.default || c))
export const StocksItems = () => import('../../components/Stocks/StocksItems.vue' /* webpackChunkName: "components/stocks-items" */).then(c => wrapFunctional(c.default || c))
export const ThanksModalContent = () => import('../../components/ThanksModal/ThanksModalContent.vue' /* webpackChunkName: "components/thanks-modal-content" */).then(c => wrapFunctional(c.default || c))
export const WishlistEmpty = () => import('../../components/Wishlist/WishlistEmpty.vue' /* webpackChunkName: "components/wishlist-empty" */).then(c => wrapFunctional(c.default || c))
export const WishlistProduct = () => import('../../components/Wishlist/WishlistProduct.vue' /* webpackChunkName: "components/wishlist-product" */).then(c => wrapFunctional(c.default || c))
export const WishlistProducts = () => import('../../components/Wishlist/WishlistProducts.vue' /* webpackChunkName: "components/wishlist-products" */).then(c => wrapFunctional(c.default || c))
export const WishlistRemoveChosen = () => import('../../components/Wishlist/WishlistRemoveChosen.vue' /* webpackChunkName: "components/wishlist-remove-chosen" */).then(c => wrapFunctional(c.default || c))
export const WishlistSort = () => import('../../components/Wishlist/WishlistSort.vue' /* webpackChunkName: "components/wishlist-sort" */).then(c => wrapFunctional(c.default || c))
export const UtilsLoadWhenVisible = () => import('../../components/utils/LoadWhenVisible.vue' /* webpackChunkName: "components/utils-load-when-visible" */).then(c => wrapFunctional(c.default || c))
export const BlogFilters = () => import('../../components/Blog/BlogFilters/BlogFilters.vue' /* webpackChunkName: "components/blog-filters" */).then(c => wrapFunctional(c.default || c))
export const BlogFiltersSearch = () => import('../../components/Blog/BlogFilters/BlogFiltersSearch.vue' /* webpackChunkName: "components/blog-filters-search" */).then(c => wrapFunctional(c.default || c))
export const BlogFiltersSort = () => import('../../components/Blog/BlogFilters/BlogFiltersSort.vue' /* webpackChunkName: "components/blog-filters-sort" */).then(c => wrapFunctional(c.default || c))
export const BrandsFilters = () => import('../../components/Brands/BrandsFilters/BrandsFilters.vue' /* webpackChunkName: "components/brands-filters" */).then(c => wrapFunctional(c.default || c))
export const BrandsFiltersCategory = () => import('../../components/Brands/BrandsFilters/BrandsFiltersCategory.vue' /* webpackChunkName: "components/brands-filters-category" */).then(c => wrapFunctional(c.default || c))
export const BrandsFiltersLetter = () => import('../../components/Brands/BrandsFilters/BrandsFiltersLetter.vue' /* webpackChunkName: "components/brands-filters-letter" */).then(c => wrapFunctional(c.default || c))
export const BrandsFiltersSearch = () => import('../../components/Brands/BrandsFilters/BrandsFiltersSearch.vue' /* webpackChunkName: "components/brands-filters-search" */).then(c => wrapFunctional(c.default || c))
export const BrandsFiltersView = () => import('../../components/Brands/BrandsFilters/BrandsFiltersView.vue' /* webpackChunkName: "components/brands-filters-view" */).then(c => wrapFunctional(c.default || c))
export const CabinetBonusApprove = () => import('../../components/Cabinet/CabinetBonus/CabinetBonusApprove.vue' /* webpackChunkName: "components/cabinet-bonus-approve" */).then(c => wrapFunctional(c.default || c))
export const CabinetBonusInfo = () => import('../../components/Cabinet/CabinetBonus/CabinetBonusInfo.vue' /* webpackChunkName: "components/cabinet-bonus-info" */).then(c => wrapFunctional(c.default || c))
export const CabinetMailingsAgreement = () => import('../../components/Cabinet/CabinetMailings/CabinetMailingsAgreement.vue' /* webpackChunkName: "components/cabinet-mailings-agreement" */).then(c => wrapFunctional(c.default || c))
export const CabinetMailingsAgreements = () => import('../../components/Cabinet/CabinetMailings/CabinetMailingsAgreements.vue' /* webpackChunkName: "components/cabinet-mailings-agreements" */).then(c => wrapFunctional(c.default || c))
export const CabinetMailingsChannels = () => import('../../components/Cabinet/CabinetMailings/CabinetMailingsChannels.vue' /* webpackChunkName: "components/cabinet-mailings-channels" */).then(c => wrapFunctional(c.default || c))
export const CabinetOrdersEmpty = () => import('../../components/Cabinet/CabinetOrders/CabinetOrdersEmpty.vue' /* webpackChunkName: "components/cabinet-orders-empty" */).then(c => wrapFunctional(c.default || c))
export const CabinetOrdersItem = () => import('../../components/Cabinet/CabinetOrders/CabinetOrdersItem.vue' /* webpackChunkName: "components/cabinet-orders-item" */).then(c => wrapFunctional(c.default || c))
export const CabinetOrdersItems = () => import('../../components/Cabinet/CabinetOrders/CabinetOrdersItems.vue' /* webpackChunkName: "components/cabinet-orders-items" */).then(c => wrapFunctional(c.default || c))
export const CabinetOrdersProduct = () => import('../../components/Cabinet/CabinetOrders/CabinetOrdersProduct.vue' /* webpackChunkName: "components/cabinet-orders-product" */).then(c => wrapFunctional(c.default || c))
export const CabinetOrdersProducts = () => import('../../components/Cabinet/CabinetOrders/CabinetOrdersProducts.vue' /* webpackChunkName: "components/cabinet-orders-products" */).then(c => wrapFunctional(c.default || c))
export const CabinetPersonalDataAccordion = () => import('../../components/Cabinet/CabinetPersonalData/CabinetPersonalDataAccordion.vue' /* webpackChunkName: "components/cabinet-personal-data-accordion" */).then(c => wrapFunctional(c.default || c))
export const CabinetPersonalDataAnimals = () => import('../../components/Cabinet/CabinetPersonalData/CabinetPersonalDataAnimals.vue' /* webpackChunkName: "components/cabinet-personal-data-animals" */).then(c => wrapFunctional(c.default || c))
export const CabinetPersonalDataBirthday = () => import('../../components/Cabinet/CabinetPersonalData/CabinetPersonalDataBirthday.vue' /* webpackChunkName: "components/cabinet-personal-data-birthday" */).then(c => wrapFunctional(c.default || c))
export const CabinetPersonalDataDelete = () => import('../../components/Cabinet/CabinetPersonalData/CabinetPersonalDataDelete.vue' /* webpackChunkName: "components/cabinet-personal-data-delete" */).then(c => wrapFunctional(c.default || c))
export const CabinetPersonalDataPassword = () => import('../../components/Cabinet/CabinetPersonalData/CabinetPersonalDataPassword.vue' /* webpackChunkName: "components/cabinet-personal-data-password" */).then(c => wrapFunctional(c.default || c))
export const CabinetPersonalDataPersonal = () => import('../../components/Cabinet/CabinetPersonalData/CabinetPersonalDataPersonal.vue' /* webpackChunkName: "components/cabinet-personal-data-personal" */).then(c => wrapFunctional(c.default || c))
export const CabinetSidebar = () => import('../../components/Cabinet/CabinetSidebar/CabinetSidebar.vue' /* webpackChunkName: "components/cabinet-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CabinetSidebarMenu = () => import('../../components/Cabinet/CabinetSidebar/CabinetSidebarMenu.vue' /* webpackChunkName: "components/cabinet-sidebar-menu" */).then(c => wrapFunctional(c.default || c))
export const CabinetSidebarUser = () => import('../../components/Cabinet/CabinetSidebar/CabinetSidebarUser.vue' /* webpackChunkName: "components/cabinet-sidebar-user" */).then(c => wrapFunctional(c.default || c))
export const CabinetUserAuth = () => import('../../components/Cabinet/CabinetUser/CabinetUserAuth.vue' /* webpackChunkName: "components/cabinet-user-auth" */).then(c => wrapFunctional(c.default || c))
export const CabinetUserAvatar = () => import('../../components/Cabinet/CabinetUser/CabinetUserAvatar.vue' /* webpackChunkName: "components/cabinet-user-avatar" */).then(c => wrapFunctional(c.default || c))
export const CabinetUserGuest = () => import('../../components/Cabinet/CabinetUser/CabinetUserGuest.vue' /* webpackChunkName: "components/cabinet-user-guest" */).then(c => wrapFunctional(c.default || c))
export const CabinetWatchedEmpty = () => import('../../components/Cabinet/Watched/WatchedEmpty.vue' /* webpackChunkName: "components/cabinet-watched-empty" */).then(c => wrapFunctional(c.default || c))
export const CatalogAccordionQuestion = () => import('../../components/Catalog/CatalogFaq/CatalogAccordionQuestion.vue' /* webpackChunkName: "components/catalog-accordion-question" */).then(c => wrapFunctional(c.default || c))
export const CatalogFaq = () => import('../../components/Catalog/CatalogFaq/CatalogFaq.vue' /* webpackChunkName: "components/catalog-faq" */).then(c => wrapFunctional(c.default || c))
export const CatalogFilters = () => import('../../components/Catalog/CatalogFilters/CatalogFilters.vue' /* webpackChunkName: "components/catalog-filters" */).then(c => wrapFunctional(c.default || c))
export const CatalogFiltersAttribute = () => import('../../components/Catalog/CatalogFilters/CatalogFiltersAttribute.vue' /* webpackChunkName: "components/catalog-filters-attribute" */).then(c => wrapFunctional(c.default || c))
export const CatalogFiltersAvailable = () => import('../../components/Catalog/CatalogFilters/CatalogFiltersAvailable.vue' /* webpackChunkName: "components/catalog-filters-available" */).then(c => wrapFunctional(c.default || c))
export const CatalogFiltersBrand = () => import('../../components/Catalog/CatalogFilters/CatalogFiltersBrand.vue' /* webpackChunkName: "components/catalog-filters-brand" */).then(c => wrapFunctional(c.default || c))
export const CatalogFiltersCheckboxList = () => import('../../components/Catalog/CatalogFilters/CatalogFiltersCheckboxList.vue' /* webpackChunkName: "components/catalog-filters-checkbox-list" */).then(c => wrapFunctional(c.default || c))
export const CatalogFiltersChosen = () => import('../../components/Catalog/CatalogFilters/CatalogFiltersChosen.vue' /* webpackChunkName: "components/catalog-filters-chosen" */).then(c => wrapFunctional(c.default || c))
export const CatalogFiltersImagesGrid = () => import('../../components/Catalog/CatalogFilters/CatalogFiltersImagesGrid.vue' /* webpackChunkName: "components/catalog-filters-images-grid" */).then(c => wrapFunctional(c.default || c))
export const CatalogFiltersImagesList = () => import('../../components/Catalog/CatalogFilters/CatalogFiltersImagesList.vue' /* webpackChunkName: "components/catalog-filters-images-list" */).then(c => wrapFunctional(c.default || c))
export const CatalogFiltersItem = () => import('../../components/Catalog/CatalogFilters/CatalogFiltersItem.vue' /* webpackChunkName: "components/catalog-filters-item" */).then(c => wrapFunctional(c.default || c))
export const CatalogFiltersPrice = () => import('../../components/Catalog/CatalogFilters/CatalogFiltersPrice.vue' /* webpackChunkName: "components/catalog-filters-price" */).then(c => wrapFunctional(c.default || c))
export const CategoryOfferSlider = () => import('../../components/Category/CategoryOfferSlider/CategoryOfferSlider.vue' /* webpackChunkName: "components/category-offer-slider" */).then(c => wrapFunctional(c.default || c))
export const CategoryOfferSliderItem = () => import('../../components/Category/CategoryOfferSlider/CategoryOfferSliderItem.vue' /* webpackChunkName: "components/category-offer-slider-item" */).then(c => wrapFunctional(c.default || c))
export const CategoryPopularSlider = () => import('../../components/Category/CategoryPopularSlider/CategoryPopularSlider.vue' /* webpackChunkName: "components/category-popular-slider" */).then(c => wrapFunctional(c.default || c))
export const CategorySubCategories = () => import('../../components/Category/CategorySubCategories/CategorySubCategories.vue' /* webpackChunkName: "components/category-sub-categories" */).then(c => wrapFunctional(c.default || c))
export const CategorySubCategory = () => import('../../components/Category/CategorySubCategories/CategorySubCategory.vue' /* webpackChunkName: "components/category-sub-category" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryAddressForm = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryAddressForm.vue' /* webpackChunkName: "components/checkout-delivery-address-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryApartment = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryApartment.vue' /* webpackChunkName: "components/checkout-delivery-apartment" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryBlock = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryBlock.vue' /* webpackChunkName: "components/checkout-delivery-block" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryCity = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryCity.vue' /* webpackChunkName: "components/checkout-delivery-city" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryCityInput = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryCityInput.vue' /* webpackChunkName: "components/checkout-delivery-city-input" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryCitySelect = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryCitySelect.vue' /* webpackChunkName: "components/checkout-delivery-city-select" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryCitySelectApi = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryCitySelectApi.vue' /* webpackChunkName: "components/checkout-delivery-city-select-api" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryDate = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryDate.vue' /* webpackChunkName: "components/checkout-delivery-date" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryDatePicker = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryDatePicker.vue' /* webpackChunkName: "components/checkout-delivery-date-picker" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryDepartment = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryDepartment.vue' /* webpackChunkName: "components/checkout-delivery-department" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryDepartmentSelectApi = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryDepartmentSelectApi.vue' /* webpackChunkName: "components/checkout-delivery-department-select-api" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryDepartmentSelectShopWarehouse = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryDepartmentSelectShopWarehouse.vue' /* webpackChunkName: "components/checkout-delivery-department-select-shop-warehouse" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryFloor = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryFloor.vue' /* webpackChunkName: "components/checkout-delivery-floor" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryHouse = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryHouse.vue' /* webpackChunkName: "components/checkout-delivery-house" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryStreet = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryStreet.vue' /* webpackChunkName: "components/checkout-delivery-street" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryStreetSelectNP = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryStreetSelectNP.vue' /* webpackChunkName: "components/checkout-delivery-street-select-n-p" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryTimePicker = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryTimePicker.vue' /* webpackChunkName: "components/checkout-delivery-time-picker" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryType = () => import('../../components/Checkout/CheckoutDelivery/CheckoutDeliveryType.vue' /* webpackChunkName: "components/checkout-delivery-type" */).then(c => wrapFunctional(c.default || c))
export const CheckoutLiqpayForm = () => import('../../components/Checkout/CheckoutLiqpay/CheckoutLiqpayForm.vue' /* webpackChunkName: "components/checkout-liqpay-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutProductCnt = () => import('../../components/Checkout/CheckoutProducts/CheckoutProductCnt.vue' /* webpackChunkName: "components/checkout-product-cnt" */).then(c => wrapFunctional(c.default || c))
export const CheckoutProducts = () => import('../../components/Checkout/CheckoutProducts/CheckoutProducts.vue' /* webpackChunkName: "components/checkout-products" */).then(c => wrapFunctional(c.default || c))
export const CheckoutProductsItem = () => import('../../components/Checkout/CheckoutProducts/CheckoutProductsItem.vue' /* webpackChunkName: "components/checkout-products-item" */).then(c => wrapFunctional(c.default || c))
export const HomeOfferSlider = () => import('../../components/Home/HomeOfferSlider/HomeOfferSlider.vue' /* webpackChunkName: "components/home-offer-slider" */).then(c => wrapFunctional(c.default || c))
export const HomeOfferSliderSlide = () => import('../../components/Home/HomeOfferSlider/HomeOfferSliderSlide.vue' /* webpackChunkName: "components/home-offer-slider-slide" */).then(c => wrapFunctional(c.default || c))
export const HomeStocksSlider = () => import('../../components/Home/HomeStocksSlider/HomeStocksSlider.vue' /* webpackChunkName: "components/home-stocks-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductFloatCart = () => import('../../components/Product/ProductFloatCart/ProductFloatCart.vue' /* webpackChunkName: "components/product-float-cart" */).then(c => wrapFunctional(c.default || c))
export const ProductFloatCartBtn = () => import('../../components/Product/ProductFloatCart/ProductFloatCartBtn.vue' /* webpackChunkName: "components/product-float-cart-btn" */).then(c => wrapFunctional(c.default || c))
export const ProductFloatCartCnt = () => import('../../components/Product/ProductFloatCart/ProductFloatCartCnt.vue' /* webpackChunkName: "components/product-float-cart-cnt" */).then(c => wrapFunctional(c.default || c))
export const ProductGroupedItem = () => import('../../components/Product/ProductGroupedItems/ProductGroupedItem.vue' /* webpackChunkName: "components/product-grouped-item" */).then(c => wrapFunctional(c.default || c))
export const ProductGroupedItems = () => import('../../components/Product/ProductGroupedItems/ProductGroupedItems.vue' /* webpackChunkName: "components/product-grouped-items" */).then(c => wrapFunctional(c.default || c))
export const ProductHero = () => import('../../components/Product/ProductHero/ProductHero.vue' /* webpackChunkName: "components/product-hero" */).then(c => wrapFunctional(c.default || c))
export const ProductHeroImage = () => import('../../components/Product/ProductHero/ProductHeroImage.vue' /* webpackChunkName: "components/product-hero-image" */).then(c => wrapFunctional(c.default || c))
export const ProductHeroInfo = () => import('../../components/Product/ProductHero/ProductHeroInfo.vue' /* webpackChunkName: "components/product-hero-info" */).then(c => wrapFunctional(c.default || c))
export const ProductInfo = () => import('../../components/Product/ProductInfo/ProductInfo.vue' /* webpackChunkName: "components/product-info" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoAccordions = () => import('../../components/Product/ProductInfo/ProductInfoAccordions.vue' /* webpackChunkName: "components/product-info-accordions" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoCharacteristics = () => import('../../components/Product/ProductInfo/ProductInfoCharacteristics.vue' /* webpackChunkName: "components/product-info-characteristics" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDelivery = () => import('../../components/Product/ProductInfo/ProductInfoDelivery.vue' /* webpackChunkName: "components/product-info-delivery" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoDescription = () => import('../../components/Product/ProductInfo/ProductInfoDescription.vue' /* webpackChunkName: "components/product-info-description" */).then(c => wrapFunctional(c.default || c))
export const ProductSubCategories = () => import('../../components/Product/ProductSubCategories/ProductSubCategories.vue' /* webpackChunkName: "components/product-sub-categories" */).then(c => wrapFunctional(c.default || c))
export const ProductSubCategoriesCollapse = () => import('../../components/Product/ProductSubCategories/ProductSubCategoriesCollapse.vue' /* webpackChunkName: "components/product-sub-categories-collapse" */).then(c => wrapFunctional(c.default || c))
export const ProductSubCategoriesList = () => import('../../components/Product/ProductSubCategories/ProductSubCategoriesList.vue' /* webpackChunkName: "components/product-sub-categories-list" */).then(c => wrapFunctional(c.default || c))
export const ProductThumbSlider = () => import('../../components/Product/ProductThumbSlider/ProductThumbSlider.vue' /* webpackChunkName: "components/product-thumb-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductVariation = () => import('../../components/Product/ProductVariations/ProductVariation.vue' /* webpackChunkName: "components/product-variation" */).then(c => wrapFunctional(c.default || c))
export const ProductVariationImages = () => import('../../components/Product/ProductVariations/ProductVariationImages.vue' /* webpackChunkName: "components/product-variation-images" */).then(c => wrapFunctional(c.default || c))
export const ProductVariationLabels = () => import('../../components/Product/ProductVariations/ProductVariationLabels.vue' /* webpackChunkName: "components/product-variation-labels" */).then(c => wrapFunctional(c.default || c))
export const ProductVariations = () => import('../../components/Product/ProductVariations/ProductVariations.vue' /* webpackChunkName: "components/product-variations" */).then(c => wrapFunctional(c.default || c))
export const ProductCardVariation = () => import('../../components/ProductCard/ProductCardVariations/ProductCardVariation.vue' /* webpackChunkName: "components/product-card-variation" */).then(c => wrapFunctional(c.default || c))
export const ProductCardVariationImages = () => import('../../components/ProductCard/ProductCardVariations/ProductCardVariationImages.vue' /* webpackChunkName: "components/product-card-variation-images" */).then(c => wrapFunctional(c.default || c))
export const ProductCardVariationLabels = () => import('../../components/ProductCard/ProductCardVariations/ProductCardVariationLabels.vue' /* webpackChunkName: "components/product-card-variation-labels" */).then(c => wrapFunctional(c.default || c))
export const ProductCardVariations = () => import('../../components/ProductCard/ProductCardVariations/ProductCardVariations.vue' /* webpackChunkName: "components/product-card-variations" */).then(c => wrapFunctional(c.default || c))
export const CabinetBonusApproveCode = () => import('../../components/Cabinet/CabinetBonus/CabinetBonusApprove/CabinetBonusApproveCode.vue' /* webpackChunkName: "components/cabinet-bonus-approve-code" */).then(c => wrapFunctional(c.default || c))
export const CabinetBonusApprovePhone = () => import('../../components/Cabinet/CabinetBonus/CabinetBonusApprove/CabinetBonusApprovePhone.vue' /* webpackChunkName: "components/cabinet-bonus-approve-phone" */).then(c => wrapFunctional(c.default || c))
export const CabinetPersonalDataPerson = () => import('../../components/Cabinet/CabinetPersonalData/CabinetPersonalDataPerson/CabinetPersonalDataPerson.vue' /* webpackChunkName: "components/cabinet-personal-data-person" */).then(c => wrapFunctional(c.default || c))
export const CabinetPersonalDataPersonEdit = () => import('../../components/Cabinet/CabinetPersonalData/CabinetPersonalDataPerson/CabinetPersonalDataPersonEdit.vue' /* webpackChunkName: "components/cabinet-personal-data-person-edit" */).then(c => wrapFunctional(c.default || c))
export const CabinetPersonalDataPersonInfo = () => import('../../components/Cabinet/CabinetPersonalData/CabinetPersonalDataPerson/CabinetPersonalDataPersonInfo.vue' /* webpackChunkName: "components/cabinet-personal-data-person-info" */).then(c => wrapFunctional(c.default || c))
export const CabinetPersonalDataBonus = () => import('../../components/Cabinet/CabinetPersonalData/PersonalDataBonus/CabinetPersonalDataBonus.vue' /* webpackChunkName: "components/cabinet-personal-data-bonus" */).then(c => wrapFunctional(c.default || c))
export const CabinetPersonalDataBonusCollapse = () => import('../../components/Cabinet/CabinetPersonalData/PersonalDataBonus/CabinetPersonalDataBonusCollapse.vue' /* webpackChunkName: "components/cabinet-personal-data-bonus-collapse" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoAccordion = () => import('../../components/Product/ProductInfo/ProductInfoAccordions/ProductInfoAccordion.vue' /* webpackChunkName: "components/product-info-accordion" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviews = () => import('../../components/Product/ProductInfo/ProductInfoReviews/ProductInfoReviews.vue' /* webpackChunkName: "components/product-info-reviews" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviewsItem = () => import('../../components/Product/ProductInfo/ProductInfoReviews/ProductInfoReviewsItem.vue' /* webpackChunkName: "components/product-info-reviews-item" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviewsItems = () => import('../../components/Product/ProductInfo/ProductInfoReviews/ProductInfoReviewsItems.vue' /* webpackChunkName: "components/product-info-reviews-items" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoReviewsSummary = () => import('../../components/Product/ProductInfo/ProductInfoReviews/ProductInfoReviewsSummary.vue' /* webpackChunkName: "components/product-info-reviews-summary" */).then(c => wrapFunctional(c.default || c))
export const ProductReviewsStats = () => import('../../components/Product/ProductInfo/ProductInfoReviews/ProductReviewsStats.vue' /* webpackChunkName: "components/product-reviews-stats" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoSummary = () => import('../../components/Product/ProductInfo/ProductInfoSummary/ProductInfoSummary.vue' /* webpackChunkName: "components/product-info-summary" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoSummaryItem = () => import('../../components/Product/ProductInfo/ProductInfoSummary/ProductInfoSummaryItem.vue' /* webpackChunkName: "components/product-info-summary-item" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoTabs = () => import('../../components/Product/ProductInfo/ProductInfoTabs/ProductInfoTabs.vue' /* webpackChunkName: "components/product-info-tabs" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoTabsHeader = () => import('../../components/Product/ProductInfo/ProductInfoTabs/ProductInfoTabsHeader.vue' /* webpackChunkName: "components/product-info-tabs-header" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
